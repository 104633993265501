import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const DropdownItem = ({ data }) => {
  const router = useRouter();
  const { t } = useTranslation("common"); // ✅ Use common.json for translations
  const [isShow, setShowHide] = useState(false);

  const handleClick = () => {
    setShowHide((prev) => !prev);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowHide(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  // ✅ Function to get name from static translations
  const getLocalizedName = (attributes) => {
    return t(attributes.name); // Uses static translation from common.json
  };

  const MenuItem = ({ own }) => {
    return own.attributes.isUrl ? (
      <a
        target="_blank"
        className="text-gray-600 block px-4 py-1 text-xs hover:text-blue-500"
        role="menuitem"
        href={own.attributes.link}
      >
        {getLocalizedName(own.attributes)}
      </a>
    ) : (
      <Link href={`/${own.attributes.link}`} key={own.id}>
        <a className="text-gray-600 block px-4 py-1 text-xs hover:text-blue-500">
          {getLocalizedName(own.attributes)}
        </a>
      </Link>
    );
  };

  return (
    <div ref={wrapperRef}>
      {data.attributes.childrens?.data?.length ? (
        <div className="relative inline-block text-left">
          <button
            type="button"
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-medium text-gray-90 hover:bg-gray-50"
            onClick={handleClick}
          >
            {getLocalizedName(data.attributes)}
            <svg
              className="-mr-1 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isShow && (
            <div className="absolute right-0 z-40 w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {data.attributes.childrens.data.map((child) => (
                  <MenuItem key={child.id} own={child} />
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Link href={`/${data.attributes.link}`} key={data.id}>
          <a className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-xs font-medium text-gray-90 hover:bg-gray-50">
            {getLocalizedName(data.attributes)}
          </a>
        </Link>
      )}
    </div>
  );
};

export default DropdownItem;
