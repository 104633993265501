import Head from "next/head";
import router from "next/router";
import { useTranslation } from "next-i18next";

// components
import Logo from "@components/layout/Logo";
import NavbarMenu from "@components/layout/NavbarMenu";
import DropdownMenu from "@components/layout/DropdownMenu";
// import Navbar from "@components/layout/Navbar";

// utils
import WindowDimension from "@utils/WindowDimension";

import { useDataState } from "@context/menu";

export default function MainLayout({ children }) {
  const { menus } = useDataState();
  const { t } = useTranslation("layout");
  const screen = WindowDimension();

  const onToggleLanguageClick = (newLocale: string) => {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: newLocale });
  };

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta charSet="utf-8" />
        <meta name="description" content="Боловсролын хүрээлэн" />
        <meta name="title" content="Боловсролын хүрээлэн" />
        <meta name="category" content="education, paper, research" />
        <meta name="keywords" content="Боловсролын хүрээлэн" />
        <meta
          property="og:title"
          key="ogtitle"
          content="Боловсролын хүрээлэн"
        />
        <meta property="og:locale" key="oglocale" content="mn_MN" />
        <meta property="og:locality" content="Ulaanbaatar" />
        <meta property="og:region" content="UB" />
        <meta property="og:country-name" content="Mongolia" />
        <meta property="og:type" key="ogtype" content="website" />
        <meta
          property="og:description"
          key="ogdesc"
          content="Боловсролын хүрээлэн"
        />
        <title>{t("instituteTitle")}</title>
      </Head>
      <main>
        <header className="flex flex-col justify-center items-center w-full border-b border-stone-500">
          <div className="block lg:hidden w-full">
            {menus && (
              <NavbarMenu
                subTitle={t("ministryTitle")}
                title={t("instituteTitle")}
                items={menus.data}
              />
            )}
          </div>
          <div className="hidden lg:block w-full flex justify-center">
            <div className="w-full bg-[#096DD9] flex justify-center py-4 items-center">
              <div className="w-full lg:w-[1100px] border-1 justify-between flex">
                <div className="space-x-3">
                  <button
                    onClick={() => onToggleLanguageClick("mn")}
                    className="text-white text-white/[.80] text-xs w-fit"
                  >
                    Mon
                  </button>
                  <button
                    onClick={() => onToggleLanguageClick("en")}
                    className="text-white text-white/[.80] text-xs w-fit"
                  >
                    Eng
                  </button>
                </div>
                <div className="space-x-3">
                  <button className="hover:text-white text-white/[.80] text-xs w-fit">
                    <a
                      target="_blank"
                      href="https://mnier.mn/journal/index.php/research"
                    >
                      journal.mnier.mn
                    </a>
                  </button>
                  <button className="hover:text-white text-white/[.80] text-xs w-fit">
                    <a
                      target="_blank"
                      href="https://esd.mn/?fbclid=IwAR1z9DPn2MkOb9eeUF1H6-QVTsG2A-UaKTsqiFdmzc0UVwFBQF1AHe7nmRE"
                    >
                      Тогтвортой хөгжлийн боловсрол
                    </a>
                  </button>
                  <button className="hover:text-white text-white/[.80] text-xs w-fit">
                    <a target="_blank" href="http://202.5.202.91/mieredu/">
                      Цахим сан
                    </a>
                  </button>
                  <button className="hover:text-white text-white/[.80] text-xs w-fit">
                    <a target="_blank" href="https://www.medle.mn">
                      Medle.mn
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <div className="flex justify-between bg-[#FFFFFF] w-[1100px] py-3">
                <Logo title={t("instituteTitle")} />
                <div className="flex flex-row">
                  {menus && <DropdownMenu items={menus.data} />}
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="w-full bg-white flex justify-center p-3 lg:p-0 sm:p-3 min-h-[calc(100vh-293px)]">
          <div className="w-full lg:w-[1100px]">{children}</div>
        </div>
        <footer className="flex flex-col justify-center items-center bg-[#1F1F1F] p-3">
          <div className="w-full lg:w-[1100px] grid lg:grid-cols-2 gap-4 border-b border-white/[.10] py-6 items-center">
            <div className="lg:col-span-1">
              <div className="flex flex-row items-center cursor-pointer">
                <div
                  className={`text-sm ml-3 hidden lg:block xs:block leading-4 text-white`}
                >
                  <div className="text-xs text-white">
                    {t("ministryTitle")}
                  </div>
                  <div className="text-white font-bold text-xs max-w-[200px]">
                    {t("instituteTitle")}
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols lg:grid-cols-3 gap-6">
              <div className="lg:col-span-2">
                <p className="text-white/[.60]">{t("addressTitle")}</p>
                <p className="text-white/[.40] text-xs">{t("addressBody")}</p>
              </div>
              <div>
                <p className="text-white/[.60]">{t("contactUsTitle")}</p>
                <p className="text-white/[.40] text-xs">
                  {t("addressEmail")}: info@mnier.mn<br />{" "}
                  {t("addressPhonenumber")}: +976-70125286
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="text-white/[.20] py-2 text-xs">{t("copyRighted")}</p>
          </div>
        </footer>
      </main>
    </>
  );
}
